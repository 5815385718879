import {
  Box,
  Grid,
  IconButton,
  InputAdornment,
  OutlinedInput,
  Toolbar,
  Tooltip,
  Typography,
  Button,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadCsvIconButton from '../../../components/DownloadCsvIconButton';
import Iconify from '../../../components/Iconify';
import { Render } from '../../../components/Render';
import SwitchWithTooltip from '../../../components/SwitchWithTooltip';
import useAccess from '../../../hooks/useAccess';
import { useTableContext } from '../../../providers/TableProvider';

// ----------------------------------------------------------------------

const RootStyle = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: `${theme.spacing(1)} !important`,
}));

const SearchStyle = styled(OutlinedInput)(({ theme }) => ({
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': { boxShadow: theme.customShadows.z8 },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${theme.palette.grey[500_32]} !important`,
  },
}));

// ----------------------------------------------------------------------

TableToolbar.propTypes = {
  infoTooltip: PropTypes.exact({
    isTooltip: PropTypes.bool,
    tooltipText: PropTypes.string,
  }),
  disableChildrenSpacing: PropTypes.bool,
  numSelected: PropTypes.number,
  filterName: PropTypes.string,
  filterQrId: PropTypes.string,
  submitOnEnter: PropTypes.bool,
  onFilterName: PropTypes.func,
  onFilterQrId: PropTypes.func,
  onDelete: PropTypes.func,
  onDownloadExcel: PropTypes.func,
  onDownloadPdf: PropTypes.func,
  csv: PropTypes.exact({
    data: PropTypes.array,
    headers: PropTypes.array,
    filename: PropTypes.string,
    canDownloadIfEmptyData: PropTypes.bool,
  }),
  access: PropTypes.shape({
    delete: PropTypes.string,
    excel: PropTypes.string,
    pdf: PropTypes.string,
    csv: PropTypes.string,
  }),
  children: PropTypes.node,
};

export default function TableToolbar({
  infoTooltip,
  numSelected = 0,
  filterName,
  filterQrId,
  submitOnEnter = false,
  onFilterName,
  onFilterQrId,
  onDelete,
  onDownloadExcel,
  onDownloadPdf,
  csv,
  access,
  disableChildrenSpacing,
  children,
}) {
  const { t } = useTranslation();

  const [localFilterName, setLocalFilterName] = useState(filterName);
  const [localFilterQrId, setLocalFilterQrId] = useState(filterQrId);

  const tableContext = useTableContext();
  const { permissionPrefix } = tableContext || {};

  const canDownloadCsv = useAccess(access?.csv ? `${permissionPrefix}.${access.csv}` : null);

  const handleFilterOnClick = () => {
    onFilterName(localFilterName);
  };

  const handleFilterOnEnter = (event) => {
    if (event.key !== 'Enter') return;
    onFilterName(localFilterName);
  };

  const handleFilterQrIdOnClick = () => {
    onFilterQrId(localFilterQrId);
  };

  const handleFilterQrIdOnEnter = (event) => {
    if (event.key !== 'Enter') return;
    onFilterQrId(localFilterQrId);
  };

  const renderFilterContent = () => {
    if (numSelected > 0) {
      return (
        <Typography component="div" variant="subtitle1">
          {numSelected} selected
        </Typography>
      );
    }

    if (onFilterName) {
      if (submitOnEnter) {
        return (
          <Box display="flex">
            <SearchStyle
              size={'small'}
              value={localFilterName}
              onChange={(e) => setLocalFilterName(e.target.value)}
              placeholder={t('search.search')}
              onKeyDown={handleFilterOnEnter}
              startAdornment={
                <InputAdornment position="start">
                  <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
                </InputAdornment>
              }
            />
            <Box ml={0.5}>
              <IconButton onClick={handleFilterOnClick}>
                <Iconify color={'text.secondary'} icon={'eva:search-fill'} />
              </IconButton>
              {infoTooltip && (
                <Render if={infoTooltip.isTooltip}>
                  <Tooltip title={infoTooltip.tooltipText}>
                    <IconButton onClick={handleFilterOnClick}>
                      <Iconify color={'text.secondary'} icon={'material-symbols:info-outline'} />
                    </IconButton>
                  </Tooltip>
                </Render>
              )}
            </Box>
          </Box>
        );
      }

      return (
        <Box display="flex">
          <SearchStyle
            size={'small'}
            value={filterName}
            onChange={onFilterName}
            placeholder={t('search.search')}
            startAdornment={
              <InputAdornment position="start">
                <Iconify icon="eva:search-fill" sx={{ color: 'text.disabled', width: 20, height: 20 }} />
              </InputAdornment>
            }
          />
          {infoTooltip && (
            <Render if={infoTooltip.isTooltip}>
              <Box ml={0.5}>
                <Tooltip title={infoTooltip.tooltipText}>
                  <IconButton onClick={handleFilterOnClick}>
                    <Iconify color={'text.secondary'} icon={'material-symbols:info-outline'} />
                  </IconButton>
                </Tooltip>
              </Box>
            </Render>
          )}
        </Box>
      );
    }

    return null;
  };

  return (
    <RootStyle
      sx={{
        ...(numSelected > 0 && {
          color: 'primary.main',
          bgcolor: 'primary.lighter',
        }),
      }}
    >
      <Grid container item alignItems={'center'}>
        <Grid
          display={'flex'}
          flexDirection={'column'}
          container
          item
          xs={12}
          md={6}
          justifyContent={{ xs: 'center', sm: 'start' }}
        >
          {renderFilterContent()}
        </Grid>

        <Grid
          container
          item
          md={6}
          spacing={disableChildrenSpacing ? 0 : 1}
          justifyContent={{ xs: 'center', sm: 'flex-start', md: 'flex-end' }}
          alignItems={{ xs: 'center', sm: 'flex-start', md: 'flex-end' }}
          flexDirection={{ xs: 'column', md: 'row' }}
          marginTop={{ xs: '10px', md: 0 }}
        >
          {children}

          {onDownloadPdf && (
            <TableToolbarIconButton text={t('button.downloadPDF')} onClick={onDownloadPdf} icon="bi:filetype-pdf" />
          )}

          {onDownloadExcel && (
            <TableToolbarIconButton text={t('button.download')} onClick={onDownloadExcel} icon="bi:filetype-xlsx" />
          )}

          {csv && canDownloadCsv && (
            <TableToolbarItemWrapper>
              <DownloadCsvIconButton
                data={csv.data}
                canDownloadIfEmptyData={csv.canDownloadIfEmptyData}
                headers={csv.headers}
                filename={csv.filename}
              />
            </TableToolbarItemWrapper>
          )}

          {numSelected > 0 && (
            <>
              {onDelete && (
                <TableToolbarIconButton text={t('button.delete')} onClick={onDelete} icon="eva:trash-2-fill" />
              )}
            </>
          )}
          {onFilterQrId !== undefined && filterQrId !== undefined && (
            <Grid>
              <Button onClick={handleFilterQrIdOnClick}>{t('search.search')}</Button>
              <SearchStyle
                size={'small'}
                value={localFilterQrId}
                onChange={(e) => setLocalFilterQrId(e.target.value)}
                onKeyDown={submitOnEnter && handleFilterQrIdOnEnter}
                placeholder={t('search.qrId')}
              />
            </Grid>
          )}
        </Grid>
      </Grid>
    </RootStyle>
  );
}

export const TableToolbarItemWrapper = ({ children }) => (
  <Grid item xs={6} sm={'auto'} container justifyContent={'center'}>
    {children}
  </Grid>
);

TableToolbarItemWrapper.propTypes = {
  children: PropTypes.node,
};

export const TableToolbarIconButton = ({ text, onClick, color, icon, disabled }) => (
  <TableToolbarItemWrapper>
    <Tooltip title={text || ''}>
      <span>
        <IconButton onClick={onClick} color={color || 'primary'} disabled={disabled} size={'small'}>
          <Iconify icon={icon} width={20} height={20} />
        </IconButton>
      </span>
    </Tooltip>
  </TableToolbarItemWrapper>
);

TableToolbarIconButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  icon: PropTypes.string.isRequired,
  disabled: PropTypes.bool,
};

export const TableToolbarCheckbox = ({ value, text, onChange, icon, color, iconColor, sx }) => (
  <TableToolbarItemWrapper>
    <SwitchWithTooltip
      size={20}
      value={value}
      onChange={onChange}
      tooltipText={text}
      icon={icon}
      color={color}
      iconColor={iconColor}
      sx={sx}
    />
  </TableToolbarItemWrapper>
);

TableToolbarCheckbox.propTypes = {
  value: PropTypes.bool,
  onChange: PropTypes.func,
  text: PropTypes.string,
  icon: PropTypes.string,
  color: PropTypes.string,
  iconColor: PropTypes.string,
  sx: PropTypes.object,
};
