import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetConveyorTobaccoProduction } from './thunks';

import { GetConveyorTobaccoProductionReducer, ClearTobaccoProductionsReducer } from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'tobaccoHumidificationProduction',
  initialState,
  reducers: {
    ClearTobaccoProductions: ClearTobaccoProductionsReducer,
  },
  extraReducers: (builder) => {
    // GetConveyorTobaccoProduction
    addExtraReducer(builder, GetConveyorTobaccoProduction, GetConveyorTobaccoProductionReducer);
  },
});

export default slice.reducer;
