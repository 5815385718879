import { Box, List } from '@mui/material';
import _ from 'lodash';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { matchPath, useLocation } from 'react-router-dom';
import { useAuth } from '../providers/AuthProvider';
import selectors from '../redux/reducers/pageIndicators/selectors';
import { GetPageIndicators } from '../redux/reducers/pageIndicators/thunks';
import NavItem from './NavItem';

// ----------------------------------------------------------------------

NavSection.propTypes = {
  navConfig: PropTypes.array,
};

export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();
  const match = (path, end = true) => (path ? !!matchPath({ path, end }, pathname) : false);
  const { role } = useAuth();
  const dispatch = useDispatch();
  const indicators = useSelector(selectors.list);

  useEffect(() => {
    dispatch(GetPageIndicators());
  }, [dispatch]);

  return (
    <Box {...other}>
      <List disablePadding sx={{ p: 1 }}>
        {navConfig.map((item) => {
          const { roles, title, children } = item;

          const rolesWithAccess = _.chain(children).flatMap('roles').concat(roles).compact().uniq().value();

          if (!_.isEmpty(rolesWithAccess) && (!role || !rolesWithAccess.includes(role))) return null;

          return <NavItem key={title} item={item} active={match} indicators={indicators} />;
        })}
      </List>
    </Box>
  );
}
