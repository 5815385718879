import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import _ from 'lodash';

import {
  Autocomplete,
  CircularProgress,
  ListItemIcon,
  ListItemText,
  MenuItem,
  TextField,
  createFilterOptions,
  Chip,
  Box,
  IconButton,
} from '@mui/material';
import Iconify from '../Iconify';
import ExpandAutocomplete from './ExpandAutocomplete';

RHFAutocomplete.propTypes = {
  key: PropTypes.any,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.object])),
  getOptionLabel: PropTypes.func,
  label: PropTypes.string,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  autoFocus: PropTypes.bool,
  onAdd: PropTypes.func,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  isScrollable: PropTypes.bool,
  getOptionDisabled: PropTypes.func,
  isMultiSelect: PropTypes.bool,
  defaultValues: PropTypes.array,
};

export default function RHFAutocomplete({
  name,
  label,
  required,
  placeholder,
  autoFocus,
  options,
  getOptionLabel,
  onAdd,
  loading,
  getOptionDisabled,
  isScrollable,
  isMultiSelect,
  defaultValues,
  ...other
}) {
  const { control } = useFormContext();

  const [open, setOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [selectAll, setSelectAll] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState(defaultValues || []);
  const [searchedOptions, setSearchedOptions] = useState([]);
  const [selectedOptionsExpand, setSelectedOptionsExpand] = useState([]);

  const withAdd = _.isFunction(onAdd);

  const filter = createFilterOptions();

  const onGetOptionLabel = (option) => {
    const label = getOptionLabel?.(option);

    if (label) return label;

    if (!_.isEmpty(option.inputValue?.trim())) return option.inputValue;

    if (_.isString(option)) return option;

    return '';
  };

  const onFilterOptions = (options, params) => {
    const filtered = filter(options, params);

    // Suggest the creation of a new value
    if (!_.isEmpty(params.inputValue?.trim())) {
      const match = filtered.find((el) => {
        const label = onGetOptionLabel(el);
        return el === params.inputValue || label === params.inputValue;
      });

      if (!match) {
        filtered.push({
          inputValue: params.inputValue,
          title: params.inputValue,
        });
      }
    }

    return filtered;
  };

  const onChange = (field, newValue) => {
    if (getOptionDisabled?.(newValue)) return;

    if (typeof newValue === 'string') {
      field.onChange(newValue);
      setSelectedOptions(newValue);
    } else if (newValue && newValue.inputValue) {
      // Create a new value from the user input
      field.onChange(newValue.inputValue);
      setSelectedOptions(newValue.inputValue);
    } else {
      field.onChange(newValue);
      setSelectedOptions(newValue);
    }
  };
  const handleOpen = () => {
    setSelectedOptionsExpand(selectedOptions);
    setOpen(true);
  };

  const handleClose = () => {
    setSelectedOptions(selectedOptionsExpand);
    setSelectAll(false);
    setSearchTerm('');
    setOpen(false);
  };

  const handleSearchChange = (event) => {
    const searchTerm = event.target.value;
    setSearchTerm(searchTerm);

    const visibleOptions = options.filter((option) => {
      const label = onGetOptionLabel(option);
      return label.toLowerCase().includes(searchTerm.toLowerCase());
    });
    setSelectAll(false);
    setSearchedOptions(visibleOptions);
  };

  const handleSelectAll = () => {
    const optionsToSelect = searchTerm !== '' ? searchedOptions : options;
    if (selectAll) {
      setSelectedOptions((prevSelectedOptions) =>
        prevSelectedOptions.filter((option) => !optionsToSelect.includes(option))
      );
    } else {
      setSelectedOptions((prevSelectedOptions) => [...prevSelectedOptions, ...optionsToSelect]);
    }
    setSelectAll(!selectAll);
  };

  const handleSelectOption = (option) => {
    const isSelected = selectedOptions.includes(option);
    if (isSelected) {
      setSelectedOptions(selectedOptions.filter((o) => o !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleSave = (field) => {
    onChange(field, selectedOptions);
    setOpen(false);
    setSearchTerm('');
    setSelectedOptions((prevSelectedOptions) =>
      prevSelectedOptions.filter(
        (option) =>
          searchTerm.trim() === '' ||
          options
            .filter((opt) => onGetOptionLabel(opt).toLowerCase().includes(searchTerm.toLowerCase()))
            .includes(option)
      )
    );
  };

  const renderTags = (values, getTagProps) => (
    <Box sx={{ maxHeight: 200, overflowY: 'auto' }}>
      {values.map((value, index) => (
        <Chip {...getTagProps({ index })} key={value} label={onGetOptionLabel(value)} />
      ))}
    </Box>
  );

  return (
    <Controller
      name={name}
      control={control}
      render={({ field, fieldState: { error } }) => (
        <>
          <Autocomplete
            fullWidth
            options={options}
            getOptionLabel={onGetOptionLabel}
            onChange={(_, newValue) => {
              onChange(field, newValue);
            }}
            renderTags={isScrollable && renderTags}
            onBlur={(_, newValue) => field.onBlur(newValue)}
            value={field.value || null}
            {...(withAdd && { filterOptions: onFilterOptions, clearOnBlur: true })}
            renderOption={(props, option) => {
              const { inputValue } = option;

              const { key, ...rest } = props;

              const label = onGetOptionLabel(option);

              const isAddOption = inputValue && _.isFunction(onAdd);

              const _onAdd = async () => {
                const newValue = await onAdd(inputValue);

                onChange(field, newValue || inputValue);
              };

              return (
                <MenuItem
                  key={key}
                  {...rest}
                  {...(isAddOption && {
                    onClick: _onAdd,
                  })}
                >
                  <ListItemText
                    primary={label}
                    primaryTypographyProps={{ sx: { whiteSpace: 'normal', wordWrap: 'break-word' } }}
                  />
                  {isAddOption && (
                    <ListItemIcon sx={{ minWidth: 'auto!important' }}>
                      <Iconify icon={'carbon:add'} width={25} height={25} />
                    </ListItemIcon>
                  )}
                </MenuItem>
              );
            }}
            loading={loading}
            getOptionDisabled={getOptionDisabled}
            {...other}
            freeSolo={false}
            renderInput={({ inputProps: { value, ...inputProps }, InputProps, ...params }) => (
              <TextField
                label={label}
                error={!!error}
                helperText={error?.message}
                required={required}
                placeholder={placeholder}
                autoFocus={autoFocus}
                {...params}
                inputProps={{
                  ...inputProps,
                  value,
                  // // value: getOptionLabel(value) || '',
                  // onKeyDown: (e) => {
                  //   if (e.key === 'Enter') {
                  //     console.log(value);
                  //     const isNewValue = _.includes(options, value)
                  //     // onAdd(params.inputProps.value);
                  //     // console.log(options, value, isNewValue);
                  //     field.onBlur(value)
                  //     // e.stopPropagation();
                  //   }
                  // },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  ...InputProps,
                  endAdornment: (
                    <>
                      {loading ? <CircularProgress size={20} /> : null}
                      {InputProps.endAdornment}
                      {isMultiSelect && (
                        <IconButton onClick={handleOpen}>
                          <Iconify icon={'iconoir:expand'} width={25} height={25} />
                        </IconButton>
                      )}
                    </>
                  ),
                }}
              />
            )}
          />
          {isMultiSelect && (
            <ExpandAutocomplete
              key={`expand-autocomplete-${name}`}
              open={open}
              handleClose={handleClose}
              handleSearchChange={handleSearchChange}
              handleSelectAll={handleSelectAll}
              handleSelectOption={handleSelectOption}
              handleSave={handleSave}
              searchTerm={searchTerm}
              selectAll={selectAll}
              options={options}
              field={field}
              onGetOptionLabel={onGetOptionLabel}
              selectedOptions={selectedOptions}
              selectedOptionsExpand={selectedOptionsExpand}
            />
          )}
        </>
      )}
    />
  );
}
