import Iconify from '../../components/Iconify';
import { routesAccess } from '../../services/access';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  {
    title: 'dashboard',
    path: '/dashboard/app',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'admin',
    icon: getIcon('clarity:administrator-solid'),
    children: [
      {
        title: 'factories',
        path: '/dashboard/factories',
        icon: getIcon('ic:round-factory'),
        roles: routesAccess.factories,
      },
      {
        title: 'users',
        path: '/dashboard/users-without-factory',
        icon: getIcon('eva:people-fill'),
        roles: routesAccess.usersWithoutFactory,
      },
    ],
  },
  {
    title: 'factory',
    icon: getIcon('ic:round-factory'),
    children: [
      {
        title: 'users',
        path: '/dashboard/users',
        icon: getIcon('eva:people-fill'),
        roles: routesAccess.users,
      },
      {
        title: 'authenticationActivity',
        path: '/dashboard/authentication-activity',
        icon: getIcon('material-symbols:shield-person'),
        roles: routesAccess.authenticationActivity,
      },
      {
        title: 'cigars',
        path: '/dashboard/cigars',
        icon: getIcon('mdi:cigar'),
        roles: routesAccess.cigars,
      },
      {
        title: 'cigarPrices',
        path: '/dashboard/cigar-prices',
        icon: getIcon('ion:pricetag'),
        roles: routesAccess.cigarPrices,
      },
      {
        title: 'tobaccos',
        path: '/dashboard/tobaccos',
        icon: getIcon('ion:leaf'),
        roles: routesAccess.tobaccos,
      },
      {
        title: 'tobaccoMappings',
        path: '/dashboard/tobacco-mappings',
        icon: getIcon('mdi:file-tree'),
        roles: routesAccess.tobaccoMappings,
      },
      {
        title: 'taxes',
        path: '/dashboard/taxes',
        icon: getIcon('heroicons-solid:receipt-tax'),
        roles: routesAccess.taxes,
        info: getIcon('material-symbols:av-timer'),
      },
      {
        title: 'rooms',
        path: '/dashboard/rooms',
        icon: getIcon('material-symbols:meeting-room-rounded'),
        roles: routesAccess.rooms,
      },
      {
        title: 'distributors',
        path: '/dashboard/distributors',
        icon: getIcon('eva:people-outline'),
        roles: routesAccess.distributors,
      },
      {
        title: 'factoryOptions',
        path: '/dashboard/factory-options',
        icon: getIcon('eva:options-2-fill'),
        roles: routesAccess.factoryOptions,
      },
      {
        title: 'paymentCodeCigarMapping',
        path: '/dashboard/payment-code-cigar-mapping',
        icon: getIcon('mdi:barcode-scan'),
      },
    ],
  },
  {
    title: 'personnel',
    icon: getIcon('fluent:people-community-20-filled'),
    children: [
      {
        title: 'employees',
        path: '/dashboard/employees',
        icon: getIcon('fluent:person-20-filled'),
        roles: routesAccess.employees,
      },
      {
        title: 'employeeTeams',
        path: '/dashboard/employee-teams',
        icon: getIcon('fluent:people-20-filled'),
        roles: routesAccess.employeeTeams,
      },
      {
        title: 'attendances',
        path: '/dashboard/attendances',
        icon: getIcon('material-symbols:calendar-month'),
        roles: routesAccess.attendances,
      },
      {
        title: 'payrolls',
        path: '/dashboard/payrolls',
        icon: getIcon('mdi:auto-pay'),
        roles: routesAccess.payrolls,
      },
    ],
  },
  {
    title: 'cigarManufacturing',
    icon: getIcon('mdi:cigar'),
    children: [
      {
        title: 'workInProgressInventory',
        path: '/dashboard/workInProgressInventory',
        icon: getIcon('material-symbols:inventory-2'),
        roles: routesAccess.workInProgressInventory,
      },
      {
        title: 'cigarPerformance',
        path: '/dashboard/cigar-performance',
        icon: getIcon('mdi:cigar'),
        roles: routesAccess.cigarPerformance,
      },
      {
        title: 'conveyorCigarUnitProductions',
        path: '/dashboard/cigar-production',
        icon: getIcon('carbon:scis-transparent-supply'),
        roles: routesAccess.conveyorCigarUnitProductions,
      },
      {
        title: 'boxPressedProcess',
        path: '/dashboard/box-pressed-process',
        icon: getIcon('codicon:server-process'),
        roles: routesAccess.boxPressedProcess,
      },
      {
        title: 'cigarUnitProductions',
        path: '/dashboard/cigar-unit-productions',
        icon: getIcon('carbon:scis-transparent-supply'),
        roles: routesAccess.cigarUnitProductions,
      },
      {
        title: 'cigarUnitBoxProductions',
        path: '/dashboard/cigar-unit-box-productions',
        icon: getIcon('carbon:scis-transparent-supply'),
        roles: routesAccess.cigarUnitBoxProductions,
      },
      {
        title: 'cigarUnits',
        path: '/dashboard/cigar-units',
        icon: getIcon('bi:box-seam-fill'),
        roles: routesAccess.cigarUnits,
      },
      {
        title: 'cigarUnitsPeriodEnd',
        path: '/dashboard/cigar-units-period-end',
        icon: getIcon('bi:box-seam-fill'),
      },
      {
        title: 'employeeUnitProductions',
        path: '/dashboard/employeeUnitProductions',
        icon: getIcon('fluent:people-community-20-filled'),
        roles: routesAccess.employeeUnitProductions,
      },
    ],
  },
  {
    title: 'tobaccoManufacturing',
    icon: getIcon('ion:leaf'),
    children: [
      {
        title: 'tobaccoInventory',
        path: '/dashboard/tobacco-inventory',
        icon: getIcon('material-symbols:inventory-2'),
        roles: routesAccess.tobaccoInventory,
      },
      {
        title: 'tobaccoContainers',
        path: '/dashboard/tobacco-containers',
        icon: getIcon('mdi:package-variant-closed'),
        roles: routesAccess.tobaccoContainers,
      },
      {
        title: 'tobaccoProduction',
        path: '/dashboard/tobacco-production',
        icon: getIcon('carbon:scis-transparent-supply'),
        roles: routesAccess.tobaccoProduction,
      },
      {
        title: 'tobaccoHumidificationProduction',
        path: '/dashboard/tobacco-humidification-production',
        icon: getIcon('carbon:scis-transparent-supply'),
        roles: routesAccess.tobaccoHumidificationProduction,
      },
      {
        title: 'tobaccoTransferSummary',
        path: '/dashboard/tobacco-transfer-summary',
        icon: getIcon('carbon:summary-kpi'),
        roles: routesAccess.tobaccoTransferSummary,
      },
      {
        title: 'tobaccoGaleraProduction',
        path: '/dashboard/tobacco-galera-production',
        icon: getIcon('carbon:scis-transparent-supply'),
        roles: routesAccess.tobaccoGaleraProduction,
      },
      {
        title: 'tobaccoDryProduction',
        path: '/dashboard/tobacco-dry-production',
        icon: getIcon('carbon:scis-transparent-supply'),
        roles: routesAccess.tobaccoDryProduction,
      },
      {
        title: 'tobaccoTransactions',
        path: '/dashboard/tobacco-transactions',
        icon: getIcon('mdi:wallet'),
        roles: routesAccess.tobaccoTransactions,
      },
      {
        title: 'tobaccoClassificationSummary',
        path: '/dashboard/tobacco-classification-summary',
        icon: getIcon('carbon:summary-kpi'),
        roles: routesAccess.tobaccoClassificationSummary,
      },
    ],
  },
  {
    title: 'packing',
    icon: getIcon('raphael:package'),
    children: [
      {
        title: 'departmentOrder',
        path: '/dashboard/department-order',
        icon: getIcon('material-symbols:order-approve'),
        roles: routesAccess.departmentOrder,
      },
      {
        title: 'workOrders',
        path: '/dashboard/work-orders',
        icon: getIcon('material-symbols:order-approve-outline'),
        roles: routesAccess.workOrders,
      },
      {
        title: 'workInProgressInventory',
        path: '/dashboard/workInProgressInventory',
        icon: getIcon('material-symbols:inventory-2'),
        roles: routesAccess.workInProgressInventory,
      },
      {
        title: 'rawMaterialInventory',
        path: '/dashboard/rawMaterialInventory',
        icon: getIcon('bi:filetype-raw'),
        roles: routesAccess.rawMaterialInventory,
      },
      {
        title: 'finishedGoodInventory',
        path: '/dashboard/finished-good-inventory',
        icon: getIcon('fluent:shopping-bag-tag-24-filled'),
        roles: routesAccess.finishedGoodInventory,
      },
      {
        title: 'packingLists',
        path: '/dashboard/packing-lists',
        icon: getIcon('fa6-solid:boxes-packing'),
        roles: routesAccess.packingLists,
      },
      {
        title: 'finishedGoodTemplates',
        path: '/dashboard/finished-good-templates',
        icon: getIcon('gg:template'),
        roles: routesAccess.finishedGoodTemplates,
      },
    ],
  },
  {
    title: 'procurements',
    icon: getIcon('mdi:local-grocery-store'),
    children: [
      {
        title: 'purchaseOrder',
        path: '/dashboard/purchase-order',
        icon: getIcon('mdi:local-grocery-store'),
        roles: routesAccess.purchaseOrder,
      },
      {
        title: 'suppliers',
        path: '/dashboard/suppliers',
        icon: getIcon('mdi:truck-fast'),
        roles: routesAccess.suppliers,
      },
      {
        title: 'tobaccoSales',
        path: '/dashboard/tobacco-sales',
        icon: getIcon('mdi:sale'),
        roles: routesAccess.tobaccoSales,
      },
    ],
  },
  {
    title: 'finances',
    icon: getIcon('mdi:wallet'),
    children: [
      {
        title: 'invoices',
        path: '/dashboard/invoices',
        icon: getIcon('mdi:invoice'),
        roles: routesAccess.invoices,
        info: getIcon('material-symbols:av-timer'),
      },
      {
        title: 'expenses',
        path: '/dashboard/expenses',
        icon: getIcon('mdi:wallet'),
        roles: routesAccess.expenses,
      },
    ],
  },
  {
    title: 'tools',
    icon: getIcon('dashicons:admin-tools'),
    children: [
      {
        title: 'conveyorStepRecords',
        path: '/dashboard/conveyor-step-records',
        icon: getIcon('material-symbols:conveyor-belt'),
        roles: routesAccess.conveyorStepRecords,
      },
      {
        title: 'conveyorStepRecordsHistory',
        path: '/dashboard/conveyor-step-records-history',
        icon: getIcon('material-symbols:history-rounded'),
        roles: routesAccess.conveyorStepRecordsHistory,
      },
      {
        title: 'qrCodes',
        path: '/dashboard/qr-codes',
        icon: getIcon('ion:qr-code'),
        roles: routesAccess.qrCodes,
      },
      {
        title: 'conveyors',
        path: '/dashboard/conveyors',
        icon: getIcon('mdi:cigar'),
        roles: routesAccess.conveyors,
      },
      {
        title: 'cycleCount',
        path: '/dashboard/cycle-count',
        icon: getIcon('akar-icons:arrow-cycle'),
        roles: routesAccess.cycleCount,
      },
    ],
  },
];

export default navConfig;
