import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import { GetTobaccoDryProduction } from './thunks';

import { GetTobaccoDryProductionReducer, ClearTobaccoDryProductionsReducer } from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'tobaccoDryProduction',
  initialState,
  reducers: {
    ClearTobaccoDryProductions: ClearTobaccoDryProductionsReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetTobaccoDryProduction, GetTobaccoDryProductionReducer);
  },
});

export default slice.reducer;
