import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';

// -------------------------------------------------------------

export const SetAlertAndClose = createAsyncThunk(
  'alertSlice/setAlertAndCloseByTimeot',
  ({ data, timeout }, { dispatch }) => {
    dispatch(SetAlert(data));

    setTimeout(() => {
      dispatch(CloseAlert());
    }, timeout || 1500);
  }
);

const initialState = {
  status: '', // fail or success
  text: '',
  code: '',
  errors: [],
  // msg: '',
  // guid: '',
  tableValidationErrors: null,
  translationValues: {},
  contactManager: false,
  loading: 'idle',
};

export const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    SetAlert: (state, action) => {
      const { status, text, code, errors, tableValidationErrors, translationValues, contactManager } = action.payload;

      state.status = status || '';
      state.text = text || null;
      state.code = code || '';
      state.errors = errors || [];
      state.tableValidationErrors = tableValidationErrors || null;
      state.translationValues = translationValues || {};
      state.contactManager = contactManager || false;
      state.loading = status ? 'succeeded' : 'idle';
    },

    CloseAlert: () => initialState,

    SetAlertLoading: (state) => {
      state.loading = 'pending';
    },
  },
});

// Export Actions
export const { SetAlert, CloseAlert, SetAlertLoading } = alertSlice.actions;

// Export Selectors
export const selectAlert = (state) => {
  // eslint-disable-next-line no-unused-vars
  const { loading, ...rest } = state.alert;

  return rest;
};

export const selectAlertStatus = (state) => state.alert.status;
export const selectAlertMsg = (state) => state.alert.msg;
export const selectAlertCode = (state) => state.alert.code;
export const selectAlertGuid = (state) => state.alert.guid;
export const selectAlertLoading = (state) => state.alert.loading;

export default alertSlice.reducer;
