import { Badge, Box, Collapse, List, ListItemButton, ListItemIcon, ListItemText } from '@mui/material';
import { alpha, styled, useTheme } from '@mui/material/styles';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink as RouterLink } from 'react-router-dom';
import { indicatorColors } from '../constants/enums';
import { useAuth } from '../providers/AuthProvider';
import Iconify from './Iconify';

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => <ListItemButton disableGutters {...props} />)(({ theme }) => ({
  ...theme.typography.body2,
  height: 35,
  margin: theme.spacing(1, 0),
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
  indicators: PropTypes.array
};

export default function NavItem({ item, active, indicators }) {
  const { t } = useTranslation();
  const { role } = useAuth();
  const theme = useTheme();

  const isActiveRoot = active(item.path, false);

  const { title, path, icon, info, children } = item;

  const [open, setOpen] = useState(isActiveRoot);
  const [badgeCount, setBadgeCount] = useState(0); // State for the badge count


  const handleOpen = () => {
    setOpen((prev) => !prev);
  };

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  const activeSubStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',
    bgcolor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
  };

  useEffect(() => {
    if (children) {
      const isActiveSub = children.some(({ path }) => active(path));

      if (isActiveSub) setOpen(true);
    }
  }, [active, children]);


  // Precompute the badge count outside of rendering
  useEffect(() => {
    if (children) {
      const totalBadgeCount = children?.reduce((total, item) => {
        const indicatorsInfo = indicators?.find((i) => i.page === item.title);
        if (indicatorsInfo) {
          return total + indicatorsInfo.indicators.reduce((sum, indicator) => sum + (indicator.quantity || 0), 0);
        }
        return total;
      }, 0);
      setBadgeCount(totalBadgeCount);
    }
  }, [children, indicators]);

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={t(`pagesSection.${title}`)} />
          {info && info}
          {badgeCount > 0 && <Badge color="primary" badgeContent={badgeCount} sx={{ mr: '16px' }} />}
          <Iconify
            icon={open ? 'eva:arrow-ios-downward-fill' : 'eva:arrow-ios-forward-fill'}
            sx={{ width: 16, height: 16, ml: 1 }}
          />
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { roles, title, path, icon, info } = item;
              const isActiveSub = active(path);

              if (roles && (!role || !roles.includes(role))) {
                return null;
              }

              const indicatorsInfo = indicators && indicators?.find((item) => item.page === title);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ml: 4,
                    ...(isActiveSub && activeSubStyle),
                  }}
                >
                  <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
                  <ListItemText disableTypography primary={t(`pages.${title}`)} />
                  {info && info}

                  {indicatorsInfo && (
                    <Box sx={{ display: 'flex', alignItems: 'center' }}>
                      {indicatorsInfo.indicators.map((indicator) => (
                        <Badge
                          color={indicatorColors[indicator?.type] || 'primary'}
                          badgeContent={indicator.quantity}
                          key={`badge-${indicatorsInfo?.page}-${indicator?.type}`}
                          style={{ marginRight: '16px' }}
                        />
                      ))}
                    </Box>
                  )}
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={t(`pages.${title}`)} />
      {info && info}
    </ListItemStyle>
  );
}
