import { createSlice } from '@reduxjs/toolkit';
import { addExtraReducer } from '../../utils/addExtraReducer';
import initialState from './initialState';
import { GetTobaccoGaleraProduction } from './thunks';

import { GetTobaccoGaleraProductionReducer, ClearTobaccoGaleraProductionReducer } from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'tobaccoGaleraProduction',
  initialState,
  reducers: {
    ClearTobaccoGaleraProduction: ClearTobaccoGaleraProductionReducer,
  },
  extraReducers: (builder) => {
    addExtraReducer(builder, GetTobaccoGaleraProduction, GetTobaccoGaleraProductionReducer);
  },
});

export default slice.reducer;
