import { createSlice } from '@reduxjs/toolkit';

import { addExtraReducer } from '../../utils/addExtraReducer';
// initial state
import initialState from './initialState';
// thunks
import {
  CreatePurchaseOrder,
  GetPurchaseOrderById,
  GetPurchaseOrderRawMaterials,
  GetPurchaseOrderSuppliers,
  CreatePurchaseOrderSupplier,
  GetPurchaseOrders,
  MarkPurchaseOrderDone,
  UpdatePurchaseOrder,
} from './thunks';

import {
  ClearSelectedPurchaseOrderReducer,
  CreatePurchaseOrderReducer,
  GetPurchaseOrderByIdReducer,
  GetPurchaseOrderRawMaterialsReducer,
  GetPurchaseOrderSuppliersReducer,
  CreatePurchaseOrderSupplierReducer,
  GetPurchaseOrdersReducer,
  MarkPurchaseOrderDoneReducer,
  UpdatePurchaseOrderReducer,
  ResetPurchaseOrderStateReducer,
} from './reducers';

// ----------------------------------------------------------------------------

// slice
export const slice = createSlice({
  name: 'purchaseOrder',
  initialState,
  reducers: { ClearSelectedPurchaseOrderReducer, ResetPurchaseOrderStateReducer },
  extraReducers: (builder) => {
    // Create
    addExtraReducer(builder, CreatePurchaseOrder, CreatePurchaseOrderReducer);
    // Get By ID
    addExtraReducer(builder, GetPurchaseOrderById, GetPurchaseOrderByIdReducer);
    // Get All
    addExtraReducer(builder, GetPurchaseOrders, GetPurchaseOrdersReducer);
    // Update
    addExtraReducer(builder, UpdatePurchaseOrder, UpdatePurchaseOrderReducer);
    // Get Raw Materials
    addExtraReducer(builder, GetPurchaseOrderRawMaterials, GetPurchaseOrderRawMaterialsReducer);
    // Get Suppliers
    addExtraReducer(builder, GetPurchaseOrderSuppliers, GetPurchaseOrderSuppliersReducer);
    // Create Supplier
    addExtraReducer(builder, CreatePurchaseOrderSupplier, CreatePurchaseOrderSupplierReducer);
    // Mark Done
    addExtraReducer(builder, MarkPurchaseOrderDone, MarkPurchaseOrderDoneReducer);
  },
});

// Export Actions
export const {
  ClearSelectedPurchaseOrderReducer: ClearSelectedPurchaseOrder,
  ResetPurchaseOrderStateReducer: ResetPurchaseOrderState,
} = slice.actions;

export default slice.reducer;
